const state = () => ({
  errors: [],
  loading: false,
  uploadInProcess: false,
  data: [],
  total: 0,
  search: "",
  group: "",
  status: "",
  archived: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "5",
  inProcess: false,
  loadingLists: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    type: "",
    locked: "",
    internalLabel: "",
    headline: "",
    introduction: "",
    description: "",
    location: "",
    place: "",
    postcode: "",
    address: "",
    url: "",
    urlVisibilityInMinutes: "",
    publishedAt: "",
    startAt: "",
    endAt: "",
    autoOfflineAt: "",
    status: "",
    zdsList: "",
    bannerImageShow: "",
    webToken: "",
    groups: "",
    statusCodes: "",
    distribution: "",
  },
  options: {
    status: [
      { name: "Veröffentlicht", value: "ONLINE" },
      { name: "In Bearbeitung", value: "IN_PROCESS" },
      { name: "Entwurf", value: "DRAFT" },
      { name: "Ausgeblendet", value: "OFFLINE" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      {
        name: "Sortierung: Publizierung (absteigend)",
        value: "publishedAt_DESC",
      },
      {
        name: "Sortierung: Publizierung (aufsteigend)",
        value: "publishedAt_ASC",
      },
    ],
    perPage: ["5", "10", "15", "20", "25", "50"],
  },
});

export default state;
